// @ts-ignore
const DEV = global.__DEV__
  ? // @ts-ignore
    global.__DEV__
  : // @ts-ignore
  typeof __DEV__ === 'undefined'
  ? false
  : // @ts-ignore
    __DEV__

export const Constants = {
  E2E: DEV && false,
  E2E_NAMESPACE: null,
  REQUEST_TIMEOUT: 10000,
  companyInformation: {
    address1: 'The Hoxton Mix',
    address2: '3rd Floor',
    address3: '86-90 Paul Street',
    city: 'London',
    company_number: '07212205',
    email: 'help@hoxtonmix.com',
    facebook: 'https://www.facebook.com/hoxtonmix/',
    ico_number: 'ZA041914',
    instagram: 'https://www.instagram.com/hoxtonmix/',
    linkedin: 'https://www.linkedin.com/company/the-hoxton-mix/',
    mlr_number: '12883542',
    name: 'The Hoxton Mix',
    phone: '+44 (0)20 347 53374',
    phone_full: '020 3475 3374',
    postal_code: 'EC2A 4NE',
    twitter: 'https://twitter.com/hoxtonmix',
    twitter_handle: '@hoxtonmix',
  },
  defaultLocale: 'en-gb',
  events: {},
  simulate: __DEV__
    ? {}
    : {
        FORCE_LANGUAGE: '',
      },
}
