'use strict'

let _warn = require('./utils/warn')

let _warn2 = _interopRequireDefault(_warn)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

// https://developers.google.com/tag-manager/quickstart

let Snippets = {
  dataLayer: function dataLayer(_dataLayer, dataLayerName) {
    return `\n      window.${dataLayerName} = window.${dataLayerName} || [];\n      window.${dataLayerName}.push(${JSON.stringify(
      _dataLayer,
    )})`
  },
  tags: function tags(_ref) {
    let id = _ref.id,
      events = _ref.events,
      dataLayer = _ref.dataLayer,
      dataLayerName = _ref.dataLayerName,
      preview = _ref.preview,
      auth = _ref.auth

    let gtm_auth = `&gtm_auth=${auth}`
    let gtm_preview = `&gtm_preview=${preview}`

    if (!id) (0, _warn2.default)('GTM Id is required')

    let iframe = `\n      <iframe src="https://load.analytics.hoxtonmix.com/ns.html?id=${id}${gtm_auth}${gtm_preview}&gtm_cookies_win=x"\n        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`

    let script = `\n      (function(w,d,s,l,i){w[l]=w[l]||[];\n        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(
      events,
    ).slice(
      1,
      -1,
    )}});\n        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';\n        j.async=true;j.src='https://load.analytics.hoxtonmix.com/anbthremgh.js?${auth}&sort=asc';\n        f.parentNode.insertBefore(j,f);\n      })(window,document,'script','${dataLayerName}','${id}');`

    let dataLayerVar = this.dataLayer(dataLayer, dataLayerName)

    return {
      dataLayerVar: dataLayerVar,
      iframe: iframe,
      script: script,
    }
  },
}

module.exports = Snippets
