import React, { FC } from 'react'
import { Constants } from 'common/utils'
import Link from 'next/link' // we need this to make JSX compile
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'next-share'
import imageResize from 'project/image-proxy-web'

type SocialLinksType = {
  isLight?: boolean
  urlToShare?: string
  shareTitle?: string
  linkType: 'social' | 'share'
}

const SocialLinks: FC<SocialLinksType> = ({
  isLight,
  linkType,
  shareTitle: _shareTitle,
  urlToShare: _urlToShare,
}) => {
  const shareTitle =
    _shareTitle || (typeof document !== 'undefined' ? document.title : '')
  const urlToShare = _urlToShare
    ? _urlToShare
    : typeof window !== 'undefined'
    ? window.location.href
    : ''

  const { facebook, linkedin, twitter } = Constants.companyInformation
  const SocialProfile = ({}) => {
    return (
      <>
        <li>
          <Link className='a-unstyled' href={facebook}>
            <img
              loading='lazy'
              className='img-fluid cursor-pointer'
              style={{ height: 35 }}
              alt={'Facebook Icon'}
              src={imageResize(
                isLight
                  ? require('public/images/icons/homepage-fb-footer-icon-desktop-white.svg')
                  : require('public/images/icons/homepage-fb-footer-icon-desktop.svg'),
              )}
            />
          </Link>
        </li>
        <li>
          <Link className='a-unstyled' href={twitter}>
            <img
              loading='lazy'
              className='img-fluid cursor-pointer'
              style={{ height: 35 }}
              alt={'X Icon'}
              src={imageResize(
                isLight
                  ? require('public/images/icons/homepage-twitter-footer-icon-desktop-white.svg')
                  : require('public/images/icons/homepage-twitter-footer-icon-desktop.svg'),
              )}
            />
          </Link>
        </li>
        <li>
          <Link className='a-unstyled' href={linkedin}>
            <img
              loading='lazy'
              className='img-fluid cursor-pointer'
              style={{ height: 35 }}
              alt={'LinkedIn Icon'}
              src={imageResize(
                isLight
                  ? require('public/images/icons/homepage-linkedin-footer-icon-desktop-white.svg')
                  : require('public/images/icons/homepage-linkedin-footer-icon-desktop.svg'),
              )}
            />
          </Link>
        </li>
      </>
    )
  }

  const ShareLinks = ({}) => {
    return (
      <>
        <li>
          <FacebookShareButton
            style={{ width: 24 }}
            url={urlToShare}
            quote={shareTitle}
            hashtag={'#hoxtonmix'}
          >
            <img
              loading='lazy'
              className='img-fluid cursor-pointer'
              width={16}
              height={35}
              alt={'Facebook Icon'}
              src={imageResize(
                isLight
                  ? require('public/images/icons/homepage-fb-footer-icon-desktop-white.svg')
                  : require('public/images/icons/homepage-fb-footer-icon-desktop.svg'),
              )}
            />
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton
            style={{ width: 24 }}
            url={urlToShare}
            title={shareTitle}
          >
            <img
              loading='lazy'
              className='img-fluid cursor-pointer'
              width={35}
              height={35}
              alt={'X Icon'}
              src={imageResize(
                isLight
                  ? require('public/images/icons/homepage-twitter-footer-icon-desktop-white.svg')
                  : require('public/images/icons/homepage-twitter-footer-icon-desktop.svg'),
              )}
            />
          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton
            style={{ width: 24 }}
            url={urlToShare}
            title={shareTitle}
          >
            <img
              loading='lazy'
              className='img-fluid cursor-pointer'
              width={35}
              height={35}
              alt={'LinkedIn Icon'}
              src={imageResize(
                isLight
                  ? require('public/images/icons/homepage-linkedin-footer-icon-desktop-white.svg')
                  : require('public/images/icons/homepage-linkedin-footer-icon-desktop.svg'),
              )}
            />
          </LinkedinShareButton>
        </li>
      </>
    )
  }

  return (
    <ul className='list-unstyled d-flex mt-3 mb-lg-0 mb-5 mb-0 social-icons ps-md-0 ps-0 pe-md-0 pe-4'>
      {linkType === 'share' ? <ShareLinks /> : <SocialProfile />}
    </ul>
  )
}

export default SocialLinks
