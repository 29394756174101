import 'project/polyfill'
import { AppProps } from 'next/app'
import '../styles/Global.scss'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import AnalyticsContainer from 'components/util/containers/AnalyticsContainer'
import PageContainer from 'components/util/containers/PageContainer'
import LocaleContainer from 'components/util/containers/LocaleContainer'
import { BagItem, ShoppingBagContext } from 'common/useShoppingBag'
import { API } from 'project/api'
import NProgress from 'components/util/NProgress'
import ScrollOnRoute from 'components/util/ScrollOnRoute'
import useCookieRedirect, { readCookieData } from 'project/useCookieRedirect'
import ToltCouponProvider from 'components/ToltCouponProvider'

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  pageProps: {}
}
require('public/images/plans/internation-page-plugz-head-desktop.png').default

readCookieData()
let initialBag: BagItem = { addons: [], duration: 'month', slug: null }
try {
  initialBag = JSON.parse(API.storage.getItemSync!('bag'))
  if (!initialBag.addons) {
    throw 'Invalid bag detected'
  }
} catch (e) {}

const AppComponent: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  useCookieRedirect()
  const [bag, setBag] = useState<BagItem>(initialBag)

  useEffect(() => {
    if (bag) {
      API.storage.setItemSync!('bag', JSON.stringify(bag))
    }
  }, [bag])

  const getLayout =
    Component.getLayout || ((page) => <PageContainer>{page}</PageContainer>)
  const router = useRouter()

  if (router.asPath.includes('sw.js')) {
    return null
  }

  return (
    <ShoppingBagContext.Provider
      value={{
        setValue: setBag,
        value: bag,
      }}
    >
      <LocaleContainer>
        <AnalyticsContainer Component={Component} />
        <Head>
          {!process.env.prod && (
            <meta name='robots' content='noindex, nofollow' />
          )}
          <meta charSet='utf-8' />
          <meta httpEquiv='x-ua-compatible' content='ie=edge' />
          <meta name='theme-color' content='#ffffff' />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0, maximum-scale=1.0'
          />
          <meta property='fb:admins' content='453323778023845' />
          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href='/apple-touch-icon.png'
          />

          <link
            rel='icon'
            type='image/png'
            href='/favicon-96x96.png'
            sizes='96x96'
          />
          <link rel='icon' type='image/svg+xml' href='/favicon.svg' />
          <link rel='icon' type='image/x-icon' href='/favicon.ico' />

          <link rel='manifest' href='/site.webmanifest' />
          <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
          <link rel='preconnect' href='https://www.googletagmanager.com' />
          <link rel='preconnect' href='https://connect.facebook.net' />
          <link rel='preconnect' href='https://snap.licdn.com' />
          <meta name='msapplication-TileColor' content='#da532c' />
          <meta name='theme-color' content='#ffffff' />
          <link rel='manifest' href='/manifest.webmanifest' />
          <link
            rel='preload'
            as='font'
            type='font/woff2'
            href='/fonts/open-sans-v17-latin-regular.woff2'
            crossOrigin={'crossorigin'}
          />
          <link
            rel='preload'
            as='font'
            type='font/woff2'
            href='/fonts/open-sans-v17-latin-600.woff2'
            crossOrigin={'crossorigin'}
          />
          <link
            rel='preload'
            as='font'
            type='font/woff2'
            href='/fonts/open-sans-v17-latin-700.woff2'
            crossOrigin={'crossorigin'}
          />
          <link rel='dns-prefetch' href='https://images.prismic.io' />
        </Head>
        <ToltCouponProvider />
        <NProgress />
        {getLayout(
          // @ts-ignore
          <Component {...pageProps} />,
          pageProps,
        )}
      </LocaleContainer>

      <ScrollOnRoute />
    </ShoppingBagContext.Provider>
  )
}
export default AppComponent
