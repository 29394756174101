'use strict'

let _Snippets = require('./Snippets')

let _Snippets2 = _interopRequireDefault(_Snippets)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

let TagManager = {
  dataLayer: function dataLayer(_ref2) {
    let _dataLayer = _ref2.dataLayer,
      _ref2$dataLayerName = _ref2.dataLayerName,
      dataLayerName =
        _ref2$dataLayerName === undefined ? 'dataLayer' : _ref2$dataLayerName

    if (window[dataLayerName]) return window[dataLayerName].push(_dataLayer)
    let snippets = _Snippets2.default.dataLayer(_dataLayer, dataLayerName)
    let dataScript = this.dataScript(snippets)
    document.head.insertBefore(dataScript, document.head.childNodes[0])
  },
  dataScript: function dataScript(dataLayer) {
    let script = document.createElement('script')
    script.innerHTML = dataLayer
    return script
  },
  gtm: function gtm(args) {
    let snippets = _Snippets2.default.tags(args)

    let noScript = function noScript() {
      let noscript = document.createElement('noscript')
      noscript.innerHTML = snippets.iframe
      return noscript
    }

    let script = function script() {
      let script = document.createElement('script')
      script.innerHTML = snippets.script
      return script
    }

    let dataScript = this.dataScript(snippets.dataLayerVar)

    return {
      dataScript: dataScript,
      noScript: noScript,
      script: script,
    }
  },
  initialize: function initialize(_ref) {
    let gtmId = _ref.gtmId,
      _ref$events = _ref.events,
      events = _ref$events === undefined ? {} : _ref$events,
      dataLayer = _ref.dataLayer,
      _ref$dataLayerName = _ref.dataLayerName,
      dataLayerName =
        _ref$dataLayerName === undefined ? 'dataLayer' : _ref$dataLayerName,
      _ref$auth = _ref.auth,
      auth = _ref$auth === undefined ? '' : _ref$auth,
      _ref$preview = _ref.preview,
      preview = _ref$preview === undefined ? '' : _ref$preview

    let gtm = this.gtm({
      auth: auth,
      dataLayer: dataLayer || undefined,
      dataLayerName: dataLayerName,
      events: events,
      id: gtmId,
      preview: preview,
    })
    if (dataLayer) document.head.appendChild(gtm.dataScript)
    document.head.insertBefore(gtm.script(), document.head.childNodes[0])
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0])
  },
}

module.exports = TagManager
