'use strict'

let _TagManager = require('./TagManager')

let _TagManager2 = _interopRequireDefault(_TagManager)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

module.exports = _TagManager2.default
