import { FC, useEffect } from 'react'
import useLocalStorageState from 'use-local-storage-state'

type ToltCouponProviderType = {}

const ToltCouponProvider: FC<ToltCouponProviderType> = ({}) => {
  const [_, setCoupon] = useLocalStorageState<string>('couponInput', {
    defaultValue: '',
    storageSync: true,
  })
  useEffect(() => {
    const intervalId = setInterval(() => {
      if ((window as any).tolt_referral) {
        setCoupon('PARTNER20')
        clearInterval(intervalId)
      }
    }, 250) // check every 250ms

    return () => clearInterval(intervalId)
  }, [setCoupon])

  return null
}

export default ToltCouponProvider
