'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
let warn = function warn(s) {
  console.warn('[react-gtm]', s)
}

exports.default = warn
